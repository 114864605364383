*{
  box-sizing: border-box;
}

:root {
  --color-background: #121212;
  --color-background-secondary: #181818;
  --color-background-third: #caced3;
  --color-text: #FFFFFF;
  --color-text-alternate: #FFFFFF;
  --theme-color-strong: #000000;
  --color-background-highlight: #e6e6e6;
  --color-background-opaque: rgba(24, 24, 24, 0.75);
  --color-link: #A5C9FF;
  --color-button: #121212;
  --okay-color: #d3c404;
  --color-icon: #caced3;
  --logo-tagline: url('/public/logos/logo-colored-tagline-dark.svg');
  --home-logo: url('/public/logos/logo-tagline-colored-dark.svg');
  --settings: url('/public/svgs/settings.svg');
  --profile: url('/public/svgs/profile.svg');
  --logout: url('/public/svgs/logout.svg');
  --close: url('/public/svgs/close.svg');
  --mobile-home-logo: url('/public/logos/logo-colored.svg')
}
[data-theme='light'] {
  --color-background: #F8F0E3;
  --color-background-secondary: #E3EBF8;
  --color-background-third: #E3EBF8;
  --color-text: #000000;
  --color-text-alternate: #757575;
  --theme-color-strong: #FFFFFF;
  --color-background-highlight: #6F7378;
  --color-background-opaque: rgba(227, 235, 248, 0.75);
  --color-link: #0000EE;
  --color-button: #FFFFFF;
  --okay-color: #F9E076;
  --color-icon: #181818;
  --logo-tagline: url('/public/logos/logo-colored-tagline-light.svg');
  --home-logo: url('/public/logos/logo-tagline-colored-light.svg');
  --settings: url('/public/svgs/settings-light.svg');
  --profile: url('/public/svgs/profile-light.svg');
  --logout: url('/public/svgs/logout-light.svg');
  --close: url('/public/svgs/close-light.svg');
  --mobile-home-logo: url('/public/logos/logo-colored-light.svg')
}

body {
  /* height: -webkit-fill-available; */
  margin: 0;
  background-color: var(--color-background);
  color: var(--color-text);
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  /* height: -webkit-fill-available; */
  /* height: calc(var(--vh, 1vh) * 100); */
}

.page-wrap{
  /* max-height: calc((var(--vh) * 100)); */
  /* max-height: calc((var(--vh) * 100)); */
  /* max-height: -webkit-fill-available; */
  max-height: 100vh;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
}


.App{
  background-color: var(--color-background);
  color: var(--color-text)
}

button{
  background-color: var(--color-button);
  color: var(--color-text);
}

.attribution{
  position: absolute;
  font-size: 1rem;
  right: 0;
  bottom: 0;
  /* margin-top: auto;
  margin-left: auto; */
}

.attribution-link{
  color: var(--color-link);
}

.dateTitle{
  font-weight: 700;
  font-size: 4rem;
  text-align: center;
  margin: 0; 
  letter-spacing: 0.5px;
  padding-bottom: 7.5px;
}

/* .dateTitleRating{
  margin-top: -12rem;
} */

.dateSubtitle{
  font-weight: 500;
  font-style: italic;
  font-size: 3rem;
  margin: 0;
  padding-bottom: 10px;
  margin-bottom: 2rem;
  border-bottom: 1px dashed gray;
  letter-spacing: 0.5px;
  text-align: center;
}

.modal-content-container .dateTitle{
  font-size: 3.5rem;
}

.modal-content-container .dateSubtitle{
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  align-self: center;
}

.option.in-modal{
  font-size: 1.3rem;
}

.errorButton{
  background-color: var(--color-button);
  outline: none;
  border: 1px solid;
  cursor: pointer;
  width: 7%;
  font-size: 1.5rem;
  font-weight: bold;
}

.rating-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper{
  display: flex;
  /* height: calc( var(--vh) * 100);
  height: calc(100vh - 60px); */
  /* height: calc((var(--vh) * 100) - 60px); */
  /* height: calc((var(--vh) * 10)); */
  /* height: calc(100% - 60px); */
  /* height: calc((var(--vh) * 100)); */
  /* max-height: -webkit-fill-available; */
  /* max-height: calc((var(--vh) * 100) - 100px); */
  height: calc(100vh - 60px);
  justify-content: center;
}

.rating-box{
  width: 100%;
  display: flex;
  justify-content: center;
  transition: all 0.5s;
  margin-bottom: 1rem;
  font-size: 2rem;
}

.buttom-select-menu{
  display: flex;
  flex-direction: column;
}

.option{
  width: 10rem;
  font-size: 1.4rem;
  padding: 0.5rem 1.2rem;
  margin: 0.8rem;
  text-decoration:none;
  font-family: 'Inter';
  border: 0.8px solid gray;
  background-color: var(--color-button);
  color: var(--color-text);
  border-radius: 4px;
  transition: all 0.2s;
  cursor: pointer;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
}

.option:focus{
  outline: none;
}

.option.in-modal:focus{
  outline: 1.5px solid black;
}

.hide{
  display: none;
}

.show{
  display: block;
}

.option:hover{
  transform: translateY(-2px);
}

.in-modal{
  width: 11rem;
  padding: 0.6rem 0.8rem;
  margin: 0.7rem 0.3rem;
  color: white;
  font-weight: bold;
}

.rating-text-enter {
  opacity: 0;
}
.rating-text-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.rating-text-exit {
  opacity: 1;
}
.rating-text-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.rating-text-exit-done {
  opacity: 0;
}

.fade-out{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.4s, opacity 0.4s linear;
}

.fade-in{
  visibility: visible;
  opacity: 1;
  transition: visibility 1s 0.4s, opacity 0.4s linear;
}

.notebox{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notetext{
  outline: 0px none transparent;
  width: 700px;
  height: 450px;
  font-size: 1.4rem;
  resize: none;
  background-color: var(--color-background-primary);
  color: var(--color-text);
  margin-bottom: 1.25rem;
}

.notesubmit{
  width: 8rem;
  font-size: 1.3rem;
  padding: 0.3rem 0.7rem;
  margin: 0.3rem;
  text-decoration: none;
  font-family: 'Inter';
  border: 0.8px solid gray;
  background-color: var(--color-button);
  border-radius: 4px;
  transition: all 0.2s;
  cursor: pointer;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
}

.notesubmit:hover,
.notesubmit:focus{
  background-color: #1183ca;
  border-color: #1183ca;
  color: white;
  font-weight: bold;
}

.notesubmit:focus{
  outline: none;
}

.notetext-modal{
  width: 85%;
  height: 41vh;
  border: none;
}

.inner-rating-container{
  width: auto;
  /* height: 300px; */
}

.calendar-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc((var(--vh) * 100) - 60px);
  max-height: -webkit-fill-available;
}

.navbar{
  background-color: var(--color-background-secondary);
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px
}

.navbar > * {
  margin: 5px 20px;
}

.logout,
.profile-nav,
.settings-nav{
  display: block;
  background-size: 35px 35px;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center center;
  border: none;
  cursor: pointer;
  padding-bottom: 5px;
}

.profile-nav:hover,
.settings-nav:hover{
  background-color: rgb(185, 185, 185);
}

.logout{
  background: var(--logout);
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: center center;
}

.settings-nav{
  border-radius: 50%;
  background: var(--settings);
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: var(--color-icon);
}

.profile-nav{
  background: var(--profile);
  background-size: 52px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: var(--color-icon);
  border-radius: 100%;
}

.logo{
  margin-right: auto;
  background: var(--home-logo);
  width: 380px;
  height: 50px;
  background-size: 400px 500px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: var(--color-background-secondary);
}

.settings-nav{
  justify-self: flex-end;
}

/* CALENDAR STYLES */

.react-calendar {
  width: 85%;
  max-width: 800px;
  border: 1px solid #a0a096;
  background-color: var(--color-button);
  font-family: 'Inter';
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 75px;
  margin-bottom: 1em;
}

.react-calendar__navigation__label:disabled{
  color: var(--color-text);
  background-color: var(--color-background-secondary);
}

.react-calendar__navigation button {
  color: var(--color-text);
  background-color: var(--color-background-secondary);
  font-size: 25px;
  min-width: 90px;
}
.react-calendar__navigation button:disabled {
  background-color: var(--color-background-secondary);
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  color: var(--theme-color-strong);
  background-color: var(--color-background-highlight)
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: lowercase;
  font-weight: bold;
  font-size: 0.8em;
}

abbr{
  text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday {
  font-size: 25px;
  padding-bottom: 0.4em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 70px;
  font-size: 25px;
  color: var(--color-text)
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}


.react-calendar__tile:disabled {
  background-color: white;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  color: var(--theme-color-strong);
  background-color: var(--color-background-highlight)
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: var(--color-background-highlight);
  color: var(--theme-color-strong);
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: var(--color-background-highlight);
  color: var(--theme-color-strong);
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__navigation__prev2-button{
  display: none;
}

.react-calendar__navigation__next2-button{
  display: none;
}

.react-calendar__navigation__next-button,
.react-calendar__navigation__prev-button{
  background-color: var(--theme-color-strong);
}

.rating{
  color: var(--color-text);
}

.amazing{
  background: #006d05;  
}

.amazing:enabled:hover,
.amazing:enabled:focus{
  background: #007e06;
}

.great{
  background: #3d8532;
}

.great:enabled:hover,
.great:enabled:focus{
  background: #008f07;
}

.good{
  background: #88b77b;
}

.good:enabled:hover,
.good:enabled:focus{
  background: #92bd86;
}

.okay{
  background: var(--okay-color);
}

.okay:enabled:hover,
.okay:enabled:focus{
  background: #f1e225;
}

.bad{
  background: #D66C65;
}

.bad:enabled:hover,
.bad:enabled:focus{
  background: #da7972;
}

.awful{
  background: #A83030;
}

.awful:enabled:hover,
.awful:enabled:focus{
  background: #c23838;
}

.horrible{
  background: #7d0600;
}

.horrible:enabled:hover,
.horrible:enabled:focus{
  background: #9f0800;
}

.loginPage{
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: -webkit-fill-available;
  align-items: center;
  justify-content: center;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
   -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
       animation: fadein 2s;
}

/* GOOGLE BUTTON */
.login-with-google-btn {
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 5px 16px 12px 50px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 1px rgba(0, 0, 0, 0.25);
  color: var(--color-text-alternate);
  font-size: 2rem;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: var(--color-background-secondary);
  background-size: 27px;
  background-repeat: no-repeat;
  background-position: 14px 16px;
}

.login-with-google-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
  0 0 0 3px #c8dafc;
}
.login-with-google-btn:active {
  background-color: #eeeeee;
}
.login-with-google-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}
.login-with-google-btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.introSplash{
  text-align: center;
  font-size: 14rem;
  margin: 0 0 0.5rem 0;
  background: #D66C65;
  background: linear-gradient(to right, #D66C65 20%, #E5D50F 40%, #88B77B 60%, #3D8532 80%, #006D05 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tagline{
  margin: 0 0 3rem;;
  font-weight: 300;
  font-size: 2.4rem;
}

.login-logo{
  display: block;
  background: var(--logo-tagline);
  background-size: 250% 300%;
  width: 100%;
  height: 75%;
  margin-top: -4rem;
  background-repeat: no-repeat;
  background-position: center center;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.rating-modal{
  top: 40px;
  bottom: 40px;
  border: 1px solid #ccc;
  background-color: var(--color-background);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  position: absolute; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 50%;
  height: 80%;
}

.rating-modal[data-theme='dark']{
  background-color: black;
}

.rating-modal-overlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-background-opaque)
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.modal-content-container{
  display: flex;
  flex-direction: column;
}

.modal-close-btn{
  opacity: 0.5;
  width: 4rem;
  justify-self: end
}

.modal-close-btn::before{
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  color: black;
  transform: rotate(45deg);
}

.close {
  display: block;
  background: var(--close);
  background-size: 23px 23px;
  background-repeat: no-repeat;
  background-position: center center;
  width: 23px;
  height: 23px;
  border: none;
  cursor: pointer;
  opacity: 0.9;
  align-self: flex-end;
}

.close:hover{
  opacity: 1;
}

/* LOADING SPINNER */

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
  max-height: -webkit-fill-available
}

.button-container{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-top: -0.5rem;
}

.footer-modal{
  position: absolute;
  text-align: center;
  width: 6.5rem;
  bottom: 0;
  margin: 1rem;
}

.edit{
  left: 0;
}

.delete{
  right: 0;
}


.profile-container,
.settings-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100 * var(--vh) - 60px); 
  /* flex: 1; */
  max-height: -webkit-fill-available;
}

.profile-info,
.settings-info{
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-text);
  align-items: center;
  height: 80%;
  width: 50%;
}

.settings-info{
  justify-content: flex-start;
  width: 40%;
}

.setting,
.profile-feature{
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-feature:last-of-type{
  margin-top: auto;
  display: flex;
  justify-content: center;
}

.profile-feature-contents{
  font-size: 1.2rem;
  text-align: end;
}

.setting{
  position: relative;
  background-color: var(--color-background-secondary);
}

.setting > *{
  margin: 1.5rem 1rem;
}
.setting::after{
  position: absolute;
  content: '';
  bottom: 0;
  left: 10%;
  width: 80%;
  border-bottom: 1.5px var(--color-background-highlight) solid;
}
/* PHONE MEDIA QUERY */
@media screen and (max-width: 700px) {
  .page-wrap{
    position: relative;
    height: 100%;
  }

  .login-with-google-btn{
    font-size: 1.2rem;
    width: 80%;
    max-width: 250px;
    text-align: end;
    padding: 6px 16px 12px 16px;
    background-size: 10%;
    background-position: 0% 100%;
    background-origin: content-box;
  }
  .react-calendar{
    width: 87.5%;
    line-height: 1.125em;
  }
  .react-calendar__month-view__weekdays__weekday{
    font-size: 15px;
  }
  .react-calendar__tile{
    line-height: 24px;
    font-size: 16px;
  }
  .react-calendar__navigation button{
    min-width: 47px;
  }
  .react-calendar__navigation button{
    font-size: 18px;
  }
  .react-calendar__navigation{
    height: 52px;
  }
  .dateTitle{
    font-size: 2.5rem;
  }
  .modal-content-container .dateTitle {
    font-size: 3rem;
    text-align: center;
}
  /* .dateTitleRating{
    max-width: 85%;
  } */
  .dateSubtitle{
    font-size: 2rem;
  }
  .dateTitleRating + .dateSubtitle{
    max-width: 85%;
    margin-bottom: 1rem;
  }
  .logo{
    background: var(--mobile-home-logo);
    width: 50px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 300px 300px;
  }

  .profile-nav{
    margin-top: auto;
    margin-bottom: auto;
  }


  .settings-nav{
    margin: auto 0;    
  }

  .profile-nav,
  .settings-nav{
    width: 45px;
    height: 70%;
    background-size: 50px;
  }

  .rating-modal{
    width: 80%;
    height: 80%;
    margin-top: auto;
    margin-bottom: auto;
  }
  .in-modal{
    width: 10rem;
    padding: 0.5rem 0.8rem;
    margin: 0.6rem 0.3rem;
  }
  .attribution{
    font-size: 0.7rem;
  }
  .notetext{
    width: 140%;
    font-size: 1.25rem;
    height: 50vh;
  }
  .rating-box{
    font-size: 1.75rem;
  }
  .notetext-modal{
    width: 250px;
    height: 350px;
    margin-bottom: 2rem;
    font-size: 1.15rem;
  }
  /* .attribution{
    margin: 3px;
  } */
  .settings-info,
  .profile-info{
      width: 85%;
      height: 85%;
  }
  .setting-label{
    font-size: 1rem;
    margin: 1rem;
  }
  .setting{
    position: relative;
    background-color: var(--color-background-secondary);
  }
  .setting::after{
    position: absolute;
    content: '';
    bottom: 0;
    left: 10%;
    width: 80%;
    border-bottom: 1.5px var(--color-background-highlight) solid;
  }
  .profile-feature:last-of-type{
    width: 50%;
  }
  .profile-feature-label{
    font-size: 1.2rem;
  }
  .profile-feature-contents{
    font-size: 1rem;
  }
  .darkModeToggle[style]{
    margin-right: -1rem;
    width: 100px !important;
  }
}

@media screen and (max-width: 375px){

  .login-with-google-btn{
    background: none;
    width: 60%;
    font-size: 1.1rem;
    padding: 5px 10px;
    text-align: center;
    margin-top: -4rem;
  }

  .dateTitleRating {
    margin-top: 0;
  }

  .notetext{
    height: 40vh;
  }

  .option{
    width: 10rem;
    font-size: 1rem;
    padding: 0.3rem 0.8rem;
    margin: 0.4rem;
  }
}

/* TABLET MEDIA QUERY */
@media screen and (min-width: 700px) and (max-width: 1250px) {
  .react-calendar{
    width: 85%;
  }
  .react-calendar__navigation button{
    font-size: 25px;
    min-width: 90px;
  }
  .react-calendar__tile{
    font-size: 25px;
  }
  .rating-modal{
    width: 80%;
  }
  .in-modal{
    padding: 0.5rem 2rem;
    margin: 0.7rem 0.3rem;
  }
  .settings-info{
    width: 60%;
  }
  .button-container h2{
    font-size: 2rem;
  }
  .profile-feature:last-of-type {
    width: 50%;
  }
  .setting-label{
    font-size: 1.2rem;
  }
  .modal-content-container .dateSubtitle{
    width: auto !important;
  }
  .profile-nav, .settings-nav{
    background-size: 55px;
  }
  .notetext{
    width: 600px;
  }
  .notetext-modal{
    font-size: 1.6rem;
    width: 85%;
    height: 40vh;
  }
  .login-logo{
    background-size: 1700px 2500px;
    width: 700px;
    height: 800px;
    margin: -8rem;
  }
  .profile-feature-contents{
    font-size: 1.1rem;
  }
}